export enum OrgStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Blocked = 'Blocked',
  Pending = 'Pending',
}
export enum OrgFeatures {
  JudgeReimbursementStatement = 'JudgeReimbursementStatement',
}

export enum OrgFeatureStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}
